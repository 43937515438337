import React, { Fragment } from 'react';
import styles from '../../../../styles/modules/ui-components/ui-textfield.module.scss';
import { validateInput } from '../../../../utils';
interface UITextFieldProps {

    label: string,
    id?: string,
    type?: string | 'text',
    onChange: (e: { target: { name: string, value: string } }) => void,
    onKeyDown?: (e: any) => void,
    onKeyUp?: (e: any) => void,
    onBlur?: (e: { target: { name: string, value: string } }) => void,
    hasError?: boolean,
    isRequired?: boolean,
    isDisabled?: boolean,
    regExp?: any,
    inputAttr?: object,
    error?: string,
    noLabel?: boolean | false,
    customValidation?: (e) => { isValid: boolean, msg?: string },
    value?: string,
    className?: string,
    propsError?: string,
    isForm?: boolean,
    onFocus?: (e: any) => void,
    readOnly?: boolean,
    noLabe?: boolean,
    name?: string,
    onKeyPress?: (e: any) => void,
    isAutoFocus?: boolean | false,
    pattern?: string,
    placeholder?: string,
    maxLength?: any,
    isEnter?: any,
    enterClick?: any,
    addressEnter?: any,
    enterEnabled?: boolean,
    calendar?: boolean,
    isActivation?: boolean,
}

interface UITextFieldState {
    isvalid: unknown,
    required?: boolean,
    errortext?: unknown,
    value?: string,
    enterClicked: boolean,
    calendar?: boolean,
    isActivation?: boolean,
}

class UITextField extends React.Component<UITextFieldProps, UITextFieldState> {
    constructor(props) {
        super(props);
        this.state = {
            isvalid: this.props.hasError || true,
            required: this.props.isRequired || false,
            errortext: this.props.error || null,
            value: this.props.error || '',
            enterClicked: false,
            calendar: this.props.calendar || false,
        }
    }

    static getDerivedStateFromProps = (props: any, state: any) => {
        if (state.value !== props.value) {
            let data = { value: props.value }
            if (props.value && state.errortext && state.errortext.toString().toLowerCase() == "required field") {
                data['errortext'] = null
            }
            return data
        }
        if (props.error && state.errortext !== props.error) {
            return {
                errortext: props.error
            }
        }
        return null;
    }

    mathRegExp = (value: string) => {
        let regExp = this.props.regExp;
        if (!value.match(regExp)) {
            return false;
        }
        else {
            return true;
        }
    }
    checkIsValueValid = (e: any) => {
        let value = e.target.value.trim(),
            regExp = this.props.regExp;


        if (this.state.required) {

            if (!value) {
                this.setState(
                    {
                        isvalid: false,
                        errortext: 'Required field'
                    }
                );
                return false;
            }
        }
        else {
            this.setState(
                {
                    isvalid: true,
                    errortext: null
                }
            );
            // if(this.props.onBlur) {

            //     this.props.onBlur(e);

            // }

        }
        if (regExp && value) {

            if (!this.mathRegExp(value)) {

                this.setState({
                    isvalid: false,
                    errortext: `Please enter a valid ${this.props.label}`
                });
                return false;
            }
            else {

                this.setState({
                    isvalid: true,
                    errortext: null
                });
                // if(this.props.onBlur) {

                //     this.props.onBlur(e);

                // }


            }
        }
        else {
            this.setState({
                isvalid: true,
                errortext: null
            });
        }

        if (this.props.customValidation) {

            let response = this.props.customValidation(e);

            if (!response.isValid) {
                this.setState({
                    isvalid: false,
                    errortext: response.msg
                });
            }
        }

        this.props.onBlur && this.props.onBlur(e)

    }
    render() {

        const { hasError, id, label, onChange, onKeyDown, onKeyUp, onBlur, isDisabled, inputAttr, type, noLabel, value, className, propsError, onFocus, readOnly, isForm, isRequired, isAutoFocus,placeholder, maxLength, isEnter, enterClick , enterEnabled, calendar, isActivation} = this.props;
        const { isvalid, errortext } = this.state
        return (
            <Fragment>
                <div className={`${styles.textfield} ${className ? className : ''}`.trim()} data-valid={!(propsError || errortext)}>
                    {!noLabel && <label className={styles.textfield__label} htmlFor={id}> {label} {isRequired && <em>*</em>} </label>}
                    
                    <input maxLength={maxLength} value={value} name={id} type={type} 
                        placeholder={noLabel? label : (placeholder?placeholder:'')} 
                        pattern={this.props.pattern} disabled={isDisabled} 
                        className={`${styles.textfield__input} ${isDisabled ? styles.textfield__disabled : ''} ${isActivation && styles.activationTextField} ${isEnter && styles.is_entered_padding_right}`} id={id} 
                        onChange={e => {this.setState({enterClicked:false}); 
                        onChange(validateInput(e));; isForm ? this.setState({ errortext: null, isvalid: true, }) : "" }} 
                        onKeyDown={onKeyDown} 
                        onKeyUp={onKeyUp} 
                        onBlur={e => {
                            this.checkIsValueValid(e) 
                            if(onBlur && typeof onBlur == "function"){
                                onBlur(e)
                            }
                        }}
                        onFocus={(e) => { onFocus && onFocus(e) }} {...inputAttr} readOnly={readOnly} autoComplete={'false'} onKeyPress={(e) => {this.props.onKeyPress && this.props.onKeyPress(e); if(enterClick && (e.code==='Enter' || e.key === "Enter")){this.setState({enterClicked:true}); enterClick(value);}}} autoFocus={isAutoFocus} />
                    { isEnter && <span onClick={(e)=>{this.setState({enterClicked: true}); enterClick(value)}} className={`${value && !this.state.enterClicked && enterEnabled && value?.length>2 ? styles.enabled_enter : styles.disabled_enter} enter_button`}><h6>ENTER</h6></span> }
                    { isEnter && <span onClick={(e)=>{this.setState({enterClicked: true}); enterClick(value)}} className={value && !this.state.enterClicked && enterEnabled && value?.length>2 ? styles.enabled_enter_arrow : styles.disabled_enter_arrow}></span> }
                    { calendar && <span className={`${styles.calendarIcon}`}></span>}
                    

                    {(propsError || errortext) && <span  className={styles.textfield__error}>{propsError ? propsError : errortext} </span>}
                    {isEnter && <div className={value && !this.state.enterClicked && enterEnabled && value?.length>2 ? styles.enter_info : styles.disabled_enter_info}>Press <div className={styles.enter_button}>ENTER</div><span></span> after filling address</div>}
                </div>
            </Fragment>
        );
    }
}



export default UITextField;
