import React, {useEffect} from 'react';
import UITextField from '../../common/ui-components/input/UITextField';
import Button from '../../common/ui-components/input/Button';
import styles from '../../../styles/modules/components/SelfServe/phone-input-step.module.scss';
import TrackerStyles from '../../../styles/modules/components/tracker.module.scss';

const UserInput = (props) => {
    const { onSearch, noResultsFound, loading, prevSearchValid, searchComplete, prevSearchValue } = props;
    const [searchValue, setSearchValue] = React.useState<string>('');
    // Button Enable/Disable deciding paramater
    const [searchValueError, setSearchValueError] = React.useState<string | null>(null);
    // Boolean Parameter for correct/wrong phone number
    const [searchValueValid, setSearchValueValid] = React.useState<boolean | false>(false);

    useEffect(() => {
        window.scrollTo({top:0,left:0,'behavior': 'smooth'});
    }, []);
    useEffect(() => {
        if (searchComplete && prevSearchValue === searchValue) {
            if (!prevSearchValid) {
                setSearchValueError("Invalid search value");
            } else if (noResultsFound) {
                setSearchValueError("No results found");
            }
        }
    }, [searchComplete, prevSearchValid, prevSearchValue, searchValue, noResultsFound]);

    const callSearch = (searchValue) => {
        if (searchValueValid) {
            onSearch(searchValue);
        }
    }
    // Handling Value Change Event
    const onSearchValueChange = (e:{target: {name: string, value: string}}) => {
        let value = e.target.value.trim();
        setSearchValueError(null);
        setSearchValueValid(false);
        if (!value) {
            setSearchValue('');
            setSearchValueValid(false);
        } else {
            setSearchValue(value);
            setSearchValueValid(true);
        }
    }
    return (
        <div className={`${styles.input_parent} ${TrackerStyles.tracker_home_parent}`}>
            <div className={TrackerStyles.top_heading} style={{'textAlign': 'center'}}><h5>Enter Details</h5></div>
            <UITextField
                className={styles.input}
                isRequired={true}
                type="text"
                isForm={true}
                value={searchValue !== null ? searchValue : ''}
                label='Mobile Number / Email / Service Request ID'
                noLabel={true}
                hasError={searchValueError !== null}
                propsError={searchValueError !== null ? searchValueError : undefined}
                onChange={(e:{target: {name: string, value: string}}) => onSearchValueChange(e)}
                onKeyUp={(e:any) => {
                    if (e.keyCode === 13) {
                        callSearch(searchValue);
                    }
                }}
            />
            <div className={styles.button_actions_sticky}>
                <Button
                    className={styles.cart_btn}
                    text={"Track Request"}
                    variant='primary'
                    onClick={() => {
                        callSearch(searchValue);
                    }}
                    loading={loading}
                    disabled={!searchValueValid}
                />
            </div>
        </div>
    )
}
export default UserInput;
