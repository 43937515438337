import { PushToPage } from "../utils/tracking";
import { pushToDatalayer } from "../utils/tracking/gtmTracking";

// To Track Page load Event
export const trackPage = (page, data) => {
    try{
        PushToPage(
            { "gtm":true, "segment": true},
            {
                "segment":{"event":"Page View", "payload":{'page_type':page,...data}},
                "gtm":{"event":"pageview","payload":{page_type:page,category:'Page View',...data}}
            }
        )
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const raiseServiceRequestTrigeer = () => {

    try{
        pushToDatalayer("RSR_Website_Header",{'category': 'Raise Service Request',
        'action': 'RSR_Website_Buttonclick',
        'page_name': 'Home Page',
        'field_name': 'Raise Service Request'})
    }catch{
        console.log('error while pushing to datalater')
    }
}