import React,{Fragment} from 'react';
import Header  from '../../../components/common/Header';
import Footer from '../../../components/common/Footer';
import Section from '../../../components/common/Section';
import UserInput from '../../../components/tracker/user-input/UserInput';
import Head from 'next/head';
import styles from '../../../styles/pages/selfserve.module.scss';
import TrackerStyles from '../../../styles/modules/components/tracker.module.scss';
import { searchServiceRequests } from '../../../redux/actions/trackerActions';
import { connect } from 'react-redux';
import { withRouter, NextRouter } from 'next/router';
import { trackerClickTrack } from '../../../tracking/trackerTracking';
import Config from '../../../config';
import { trackPage } from '../../../tracking';
import { WebsiteSchema } from '@/constants/schema';
import SchemaLoader from '@/components/hoc/SchemaLoader';
import { Hidden } from '@mui/material';
import Image from 'common/components/UI/Image';

interface TrackerSearchProps {
    trackerReducer: any,
    searchServiceRequests: (value:any, page:number) => void,
    router: NextRouter,
}

class SRTrackerUserInput extends React.Component<TrackerSearchProps> {
    onSearch = (value:any) => {
        const page = 1;
        trackerClickTrack({}); // GTM Trigger - search;
        this.props.searchServiceRequests(value, page);
    }

    componentDidMount() {
        trackPage("Track Service Request Enter Detail Viewed",{})
        const url = window.location.href;
        if (url.includes("#service_request")) {
            const ref_no_match = url.match(/\d{6}-\d{6}/);
            if (ref_no_match) {
                this.props.router.replace(`/selfserve/tracker/service-request/${ref_no_match[0]}`);
            }
        }
    }
    componentDidUpdate (prevProps) {
        if (prevProps.trackerReducer.search.inProgress && !this.props.trackerReducer.search.inProgress) {
            const { trackerReducer } = this.props;
            if (trackerReducer.search.count > 1) {
                this.props.router.push('/selfserve/tracker/service-request');
            } else if (trackerReducer.search.count === 1) {
                const sr = trackerReducer.search.results[0];
                this.props.router.push(`/selfserve/tracker/service-request/${sr.ref_no}`);
            }
        }
    }

    render() {
        const title = "Track Service Request"
        const { trackerReducer, router } = this.props;
        const searchComplete = !trackerReducer.search.inProgress && trackerReducer.search.value !== null;
        const BASE_URL = Config.BASE_URL
        return (
            <Fragment>
                <Head>
                    <title>Track Service Request | Onsitego</title>
                    <link rel="icon" href="/favicon.ico" />
                    <meta name="description" content={"Track your service request here. Onsitego is India’s #1 Rated Device Protection Programme."}></meta>
                    <meta property="og:title" content={"Track Service Request | Onsitego"}/>
                    <meta property="og:description" content={"Track your service request here. Onsitego is India’s #1 Rated Device Protection Programme."}/>
                    <meta property="og:url" content={`${BASE_URL}${router.asPath}`}/>
                    <link rel="canonical" href={`${BASE_URL}${router.asPath}`}></link>
                    <SchemaLoader schema={WebsiteSchema} />
                </Head>
                <Header channel="website" page="tracker" IsCoBranded={false} withNav={false} IsInverse={true} withCart={false} productType="" showPhoneNumber={false}/>
                <Hidden only={['md','lg','xl']}>
                    <div className={`${styles.banner_container_hp}`}>
                        <Image src="/static/images/selfserve/hardik_pandya_banner_mobile.webp" height="250" width="960" objectFit="fill"></Image>
                    </div>
                </Hidden>
                <Hidden only={['xs','sm','lg','xl']}>
                    <div className={`${styles.banner_container_hp}`}>
                        <Image className={styles.tabs_views} src="/static/images/selfserve/hardik_pandya_banner_tablet.webp" height="400" width="1280" objectFit="fill"></Image>
                    </div>
                </Hidden>
                <Hidden only={['xs','md','sm']}>
                    <div className={`${styles.banner_container_hp}`}>
                        <Image src="/static/images/selfserve/hardik_pandya_banner_desktop.webp" height="380" width="1920" objectFit="fill"></Image>
                    </div>
                </Hidden>
                
                <Section dataBg="shade-2" style={{paddingTop:'1px',paddingBottom:'100px',position:'relative'}}>
                    <div className="container" id={"tracker-bottom-sticky"}>
                        <div className={`${styles.self_serve_parent} ${styles.hardik_Style}`}>
                            <h3 className={TrackerStyles.main_heading}>{title}</h3>
                            <UserInput
                                onSearch={this.onSearch}
                                searchComplete={searchComplete}
                                prevSearchValid={trackerReducer.search.valid}
                                noResultsFound={trackerReducer.search.count === 0}
                                prevSearchValue={trackerReducer.search.value}
                                loading={trackerReducer.search.inProgress} />
                        </div>
                    </div>
                </Section>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        trackerReducer: state.trackerReducer,
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        searchServiceRequests: (value:any, page:number) => dispatch(searchServiceRequests(value, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(SRTrackerUserInput));
